import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import {
  MainBlock,
  SecondaryBlock,
  VariantBlock,
} from "../components/block/block"
import "../components/pageStyles/productPage.css"

// Querying the product information from Sanity using GrapQL
export const query = graphql`
  query($slug: String) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      title
      usp
      featured_image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      blocks {
        _key
        title
        body
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      has_variants
      slug {
        current
      }
      options_with_values {
        attribute_1_name

        attribute_1_values {
          values
          size
          sub_variants {
            bed_depth
            cfm
            cmh
            decibels
            diameter
            wattage
            weight
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  console.log(data.sanityProduct.options_with_values)
  return (
    // Wraping page in Layout Component
    <Layout>
      {/* Wrap in product page div to control responsive display */}
      <div className="product-page">
        {/* Main block with title and USP */}
        <MainBlock
          title={data.sanityProduct.title}
          body={data.sanityProduct.usp}
          image_url={data.sanityProduct.featured_image.asset.fluid}
        />

        {/* render all blocks relevant to the product */}
        {data.sanityProduct.blocks.map(block => (
          <SecondaryBlock
            title={block.title}
            body={block.body}
            image_url={block.image ? block.image.asset.fluid : 0}
            image_alt={data.sanityProduct.title}
          />
        ))}
        {/* render the variants block */}
        {/* <IfVariants subject={data.sanityProduct.options_with_values} /> */}
        {data.sanityProduct.options_with_values && (
          <VariantBlock
            title={data.sanityProduct.options_with_values.attribute_1_name}
            variants={data.sanityProduct.options_with_values.attribute_1_values}
            details={data.sanityProduct.options_with_values.attribute_1_values}
          />
        )}
        <div className="calculator-block"></div>
      </div>
      <h1 className="transparent-title">{data.sanityProduct.title}</h1>
    </Layout>
  )
}
