import React, { useState, useRef } from "react"
import Image from "gatsby-image/withIEPolyfill"
import "./block.css"
import { useInView } from "react-intersection-observer"
import { useWindowSize } from "../windowSize/windowSize"
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import airflow from "../../images/cmh.svg"
import decibels from "../../images/decibels.svg"
import diameter from "../../images/diameter.svg"
import wattage from "../../images/wattage.svg"
import weight from "../../images/weight.svg"
import bedDepth from "../../images/bed-depth.svg"

// Main block component for main information on product
export const MainBlock = props => (
  <div className="main-block">
    {/* Two seperate backgrounds */}
    <div className="block-bg"></div>
    {/* <div className="sub-bg"></div> */}
    {/* left side */}
    <div className="left">
      <h1 style={{ color: "#E1873D" }}>{props.title}</h1>
      <p>{props.body}</p>
    </div>
    {/* right side */}
    <div className="right">
      {/* Image component */}
      <Image
        imgStyle={{ objectPosition: "top center", objectFit: "contain" }}
        objectFit="contain"
        className="block-img"
        fluid={props.image_url}
        alt={props.image_alt}
      />
    </div>
  </div>
)

// Secondary information block
export const SecondaryBlock = props => {
  // setting window width as a local variable
  return (
    <div className="secondary-block">
      <div className="block-bg" />
      {/* Left side on large screens */}
      <div className="left">
        {/* Render title and body text */}
        <h1>{props.title}</h1>
        <p>{props.body}</p>
      </div>

      {/* right side on large screens */}
      {props.image_url !== 0 && (
        <div className="right">
          {/* sub background to sit behind the image */}
          {/* <div className="sub-bg"></div> */}
          {/* Render the image of the section */}
          <Image
            fluid={props.image_url}
            alt={props.image_alt}
            className="block-img"
            objectFit="contain"
            // objectPosition={width > 1024 ? "bottom left" : '50% 50%'}
            style={{ height: "100%" }}
          />
        </div>
      )}
    </div>
  )
}

// Variant block to show different sizes or variants
export const VariantBlock = ({ title, variants, details }) => {
  // store the window width in a local variable
  const [width] = useWindowSize()
  const desktop = width >= 1024

  const [expanded, setExpanded] = useState(false)

  const ref = useRef()

  const styles = {
    background: "transparent",
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const scrollRight = () => {
    if (!inView2) ref.current.scrollLeft += 224
  }

  const scrollLeft = () => {
    if (!inView3) ref.current.scrollLeft -= 224
  }

  // Extracting variables from useInView hook
  const [ref2, inView] = useInView({
    /* Optional options */
    threshold: 0.7,
  })

  const [ref3, inView2] = useInView({
    /* Optional options */
    threshold: 0,
  })

  const [ref4, inView3] = useInView({
    /* Optional options */
    threshold: 0,
  })

  return (
    <div ref={ref2} className="variant-block">
      {/* {desktop && ( */}

      {/* )} */}
      {/* main background with low opacity */}
      <div className="block-bg"></div>
      {/* Block title */}
      <h1
        style={{
          margin: "40px 0 20px",
          transition: "all 0.4s ease-out 0.75s",
          color: "#E1873D",
          ...(desktop && {
            padding: 0,
            margin: 0,
            flexBasis: "22%",
            maxWidth: inView ? "0px" : "500px",
            visibility: inView ? "hidden" : "visible",
            opacity: inView ? 0 : 1,
            ...(inView && {}),
          }),
        }}
      >
        {title}
      </h1>

      <span
        className="variant-nav"
        style={{
          position: "absolute",
          right: 20,
          top: !desktop ? 70 : 20,
        }}
      >
        <ArrowBackIosIcon
          style={{
            marginRight: "10px",
            opacity: inView3 ? 0.4 : 1,
          }}
          onClick={scrollLeft}
        />
        <ArrowForwardIosIcon
          style={{
            opacity: inView2 ? 0.4 : 1,
          }}
          onClick={scrollRight}
        />
      </span>

      {/* Render variants in either columns or rows depending on the amount of variants and the size of the window */}
      <div
        /*         style={{
          flexWrap:
            width < 1024 ? "nowrap" : variants.length > 6 ? "wrap" : "nowrap",
          flexDirection:
            width < 1024 ? "column" : variants.length > 6 ? "column" : "row",
          width:
            width > 1024
              ? variants.length > 6
                ? `${Math.round((variants.length / 2) * 100) * 2}px`
                : "auto"
              : "auto",
          alignItems:
            width < 1024
              ? "center"
              : variants.length > 6
              ? "center"
              : "flex-start",
        }} */
        className="variant-inner"
        ref={ref}
      >
        {desktop && (
          <span
            style={{
              width: "20px",
              height: "10px",
              flexShrink: 0,
            }}
          />
        )}
        {/* Map through the variuants array and render the results */}
        {/* {variants.map(({ values }, index) => {
          return (
            <div className="variant">
              {/* Map trough sub variant information and render underneath 
              {values.map((value, index) => (
                <h2 className={index > 0 && "subheading"}>{value}</h2>
              ))}
            </div>
          )
        })} */}
        {details.map(variant => {
          if (variant.sub_variants.length === 1) {
            console.log(variant)
            return (
              <div
                className="variant"
                style={{
                  height: "330px",
                }}
              >
                <h2>{variant.size}</h2>
                {variant.sub_variants[0].cfm && (
                  <span>
                    <img src={airflow} alt="Airflow icon" />
                    <h2 className="subheading">
                      {variant.sub_variants[0].cfm} ft³/min
                    </h2>
                  </span>
                )}

                {variant.sub_variants[0].cmh && (
                  <span>
                    <img src={airflow} alt="Airflow icon" />
                    <h2 className="subheading">
                      {variant.sub_variants[0].cmh} m³/h
                    </h2>
                  </span>
                )}

                {variant.sub_variants[0].decibels && (
                  <span>
                    <img src={decibels} alt="Decibels icon" />
                    <h2 className="subheading">
                      {variant.sub_variants[0].decibels} dB
                    </h2>
                  </span>
                )}

                {variant.sub_variants[0].diameter && (
                  <span>
                    <img src={diameter} alt="Diameter icon" />
                    <h2 className="subheading">
                      {variant.sub_variants[0].diameter} mm
                    </h2>
                  </span>
                )}

                {variant.sub_variants[0].wattage && (
                  <span>
                    <img src={wattage} alt="Wattage icon" />
                    <h2 className="subheading">
                      {variant.sub_variants[0].wattage} W
                    </h2>
                  </span>
                )}
                {variant.sub_variants[0].weight && (
                  <span>
                    <img src={weight} alt="Weight icon" />
                    <h2 className="subheading">
                      {variant.sub_variants[0].weight} kg
                    </h2>
                  </span>
                )}
              </div>
            )
          } else if (variant.sub_variants.length > 1) {
            return (
              <div className="variant">
                <h2>{variant.size}</h2>
                {variant.sub_variants.map((sub_variant, index) => {
                  return (
                    <ExpansionPanel
                      style={styles}
                      expanded={expanded === `panel${index}${variant.size}`}
                      onChange={handleChange(`panel${index}${variant.size}`)}
                    >
                      <ExpansionPanelSummary
                        // aria-controls="panel1a-content"
                        // id="panel1a-header"
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "white" }} />
                        }
                      >
                        {sub_variant.bed_depth && (
                          <span>
                            <img src={bedDepth} alt="Bed depth icon" />
                            <h2
                              className="subheading"
                              style={{ color: "white" }}
                            >
                              {sub_variant.bed_depth} mm
                            </h2>
                          </span>
                        )}
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        styles={{
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          {sub_variant.cfm && (
                            <span>
                              <img src={airflow} alt="Airflow icon" />
                              <h2 className="subheading">
                                {sub_variant.cfm} ft³/min
                              </h2>
                            </span>
                          )}

                          {sub_variant.cmh && (
                            <span>
                              <img src={airflow} alt="Airflow icon" />
                              <h2 className="subheading">
                                {sub_variant.cmh} m³/h
                              </h2>
                            </span>
                          )}

                          {sub_variant.decibels && (
                            <span>
                              <img src={decibels} alt="Decibels icon" />
                              <h2 className="subheading">
                                {sub_variant.decibels} dB
                              </h2>
                            </span>
                          )}

                          {sub_variant.diameter && (
                            <span>
                              <img src={diameter} alt="Diameter icon" />
                              <h2 className="subheading">
                                {sub_variant.diameter} mm
                              </h2>
                            </span>
                          )}

                          {sub_variant.wattage && (
                            <span>
                              <img src={wattage} alt="Wattage icon" />
                              <h2 className="subheading">
                                {sub_variant.wattage} W
                              </h2>
                            </span>
                          )}
                          {sub_variant.weight && (
                            <span>
                              <img src={weight} alt="Weight icon" />
                              <h2 className="subheading">
                                {sub_variant.weight} kg
                              </h2>
                            </span>
                          )}
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )
                })}
              </div>
            )
          }
        })}
        <span
          style={{
            width: "32px",
            height: "10px",
            flexShrink: 0,
          }}
        />
      </div>
    </div>
  )
}
